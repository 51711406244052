import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Tabs, Tab, Row } from 'react-bootstrap'
import { AnimatePresence, motion } from "framer-motion"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "react-qr-code";
import {
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";
import { NavbarPresenter } from './components/Navbar.js'
import { useGlobalState } from '../../hooks/useCustomization.js';
import { domainConfig } from "../../assets/config.js"
import { useAppState, getData } from "./context/AppContext.js"
import { PageLoader } from '../../components/Elements.js'
import { TextWithLineBreaks } from "../../components/Functions.js"
import './assets/style.scss'

const GalleryBoardAdmin = () => {
    const { dataApplication, handleExit, blockScreen } = useGlobalState();
    const { appState, updateData } = useAppState();

    useEffect(() => {
        if (appState.isConnected) {
            getData()
            const intervalId = setInterval(() => {
                getData()
            }, 15000);
            return () => clearInterval(intervalId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateData, appState.isConnected]);

    if (appState.participants) {
        if (!blockScreen) {
            return (
                <>
                    <motion.nav
                        className="position-fixed w-100 px-3 justify-content-between navbar navbar-expand"
                        key={'appsNavbar'}
                        initial={{ y: -100 }}
                        animate={{ y: 0 }}
                        transition={{ duration: 0.5 }}
                        exit={{ y: -100 }}>
                        <NavbarPresenter />
                    </motion.nav>
                    <motion.div className='presenter-box container' key="mainDiv" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                        <div className="box">
                            <div className="logoBox">
                                <div>
                                    <div className="backButton" onClick={() => handleExit()}><FontAwesomeIcon icon={faArrowLeft} size={'lg'} /></div>
                                </div>
                                <div className="logoBoxChildMiddle">
                                    <div className="pb-3">
                                        <h5 className="mb-1"><TextWithLineBreaks text={appState.titulo}></TextWithLineBreaks></h5>
                                        <span className="badge primaryColor primaryColorText mb-5 fw-normal">{appState.nomeAplicativo}</span>
                                    </div>
                                </div>
                                <div className="logoBoxHideButton">
                                    <div className="backButton"></div>
                                </div>
                            </div>
                            <Tabs
                                defaultActiveKey="k1"
                                transition={true}
                                id="noanim-tab-example"
                                className="mb-4"
                            >
                                <Tab eventKey="k1" title={`Configurações`}>
                                    <div className="row mx-0">
                                        <p className="my-5 text-center opacity-75">Nenhuma configuração disponível</p>
                                    </div>
                                </Tab>
                            </Tabs>
                        </div>
                    </motion.div >
                </>
            )
        } else {
            return (
                <div className='default-box'>
                    <div className={`box-full`}>
                        <img src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo} alt="Logo" className="App-logo"></img>
                        <p>Essa tela só está disponível<br></br>para dispositivos maiores que 600px</p>
                    </div>
                </div>
            )
        }
    } else {
        return (
            <motion.div className='default-box' key={'loaderDiv'}>
                <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
            </motion.div>
        )
    }
}

const Gallery = ({ images, columns, rows, imageWidth, imageHeight, padding }) => {
    console.log(images)
    return (
        <div
            className="gallery-container"
            style={{
                alignContent: 'center',
                display: 'grid',
                gridTemplateColumns: `repeat(${columns}, ${imageWidth}px)`,
                gridTemplateRows: `repeat(${rows}, ${imageHeight}px)`,
                gap: padding + 'rem',
            }}
        >
            <AnimatePresence>
                {images.map((image, index) => (
                    <motion.div
                        key={`gallery-item` + image.resultado_id} // Using image URL as key
                        className="gallery-item"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0, display: "none" }}
                        transition={{ duration: 1 }}
                    >
                        <img
                            src={image.media}
                            alt={`Gallery Images ${index + 1}`}
                            style={{
                                width: `${imageWidth}px`,
                                height: `${imageHeight}px`,
                                objectFit: 'cover',
                            }}

                        />
                    </motion.div>
                ))}
            </AnimatePresence>
        </div>
    );
};

export const GalleryBoardModal = () => {
    const { appState, setShowPresentation } = useAppState();
    const [mediaItems, setMediaItems] = useState([]);
    const [showCurrentMedia, setShowCurrentMedia] = useState(false);
    const { dataApplication } = useGlobalState();
    const [currentMedia, setCurrentMedia] = useState({});
    const mediaItemsRef = useRef(mediaItems);
    const addedMedia = useRef(new Set()); // Track failed media
    const failedMedia = useRef(new Set()); // Track failed media
    const retriedMedia = useRef(new Set()); // Track retried media
    const nextReplaceIndex = useRef(0); // Track the next index to replace
    const [forceUpdateKey, setForceUpdateKey] = useState(0);

    // Update the ref whenever mediaItems change
    useEffect(() => {
        mediaItemsRef.current = mediaItems;
    }, [mediaItems]);

    const preloadImages = (url) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = () => resolve(url);
            img.onerror = () => {
                console.error(`Failed to load image: ${url}`);
                resolve(null);
            };
        });
    };

    const isUpdating = useRef(false);
    const updateMediaItems = useCallback(async () => {
        if (isUpdating.current) return;
        isUpdating.current = true;
        try {
            const isInArray = (obj1, arr) => arr.some((obj2) => obj1.resultado_id === obj2.resultado_id);
            const newElements = appState.results.filter((obj1) => {
                const notInMediaItems = !isInArray(obj1, mediaItemsRef.current);
                const notInAddedMedia = !addedMedia.current.has(obj1.resultado_id);
                const notFailedOrRetried = !failedMedia.current.has(obj1.resultado_id) || !retriedMedia.current.has(obj1.resultado_id);
                return notInMediaItems && notInAddedMedia && notFailedOrRetried;
            });
            appState.results.forEach((obj1) => {
                console.log("Checking:", obj1.resultado_id);
                console.log("In Media Items:", isInArray(obj1, mediaItemsRef.current));
                console.log("In Added Media:", addedMedia.current.has(obj1.resultado_id));
                console.log("In Failed Media:", failedMedia.current.has(obj1.resultado_id));
                console.log("In Retried Media:", retriedMedia.current.has(obj1.resultado_id));
            });
            if (newElements.length > 0) {
                const newParticipant = newElements[0];
                if (newParticipant.media) {
                    const preloadedMedia = await preloadImages(newParticipant.media);
                    if (preloadedMedia) {
                        setCurrentMedia(newParticipant);
                        setShowCurrentMedia(true);

                        setTimeout(() => {
                            setShowCurrentMedia(false);
                            setTimeout(() => {
                                addedMedia.current.add(newParticipant.resultado_id);
                                setMediaItems((prevState) => {
                                    const maxItems = appState.apresentation.configs.columns * appState.apresentation.configs.rows;
                                    const updatedItems = [...prevState];

                                    if (updatedItems.length >= maxItems) {
                                        updatedItems[nextReplaceIndex.current] = newParticipant;
                                        nextReplaceIndex.current = (nextReplaceIndex.current + 1) % maxItems;
                                    } else {
                                        updatedItems.push(newParticipant);
                                    }

                                    return updatedItems;
                                });
                                isUpdating.current = false;
                            }, 500);
                        }, appState.apresentation.configs.newImageTotalTime);
                    } else {
                        isUpdating.current = false;
                        if (!failedMedia.current.has(newParticipant.resultado_id)) {
                            failedMedia.current.add(newParticipant.resultado_id);
                        } else {
                            retriedMedia.current.add(newParticipant.resultado_id);
                            setForceUpdateKey((prevKey) => prevKey + 1);
                        }
                        isUpdating.current = false;
                    }
                }
            }
        } catch (error) {
            console.error('Error updating media items:', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appState.results, forceUpdateKey]);

    useEffect(() => {
        updateMediaItems();
        const interval = setInterval(updateMediaItems, appState.apresentation.configs.transitionTime);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateMediaItems]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' || event.keyCode === 27) {
                setShowPresentation(false);
            }
        };
        document.addEventListener('keydown', handleKeyDown);
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [setShowPresentation]);

    return (
        <>
            {appState.apresentation.showQRCode && appState.apresentation.showQRCode.status && (
                <div className="modalQRCodeShare">
                    <div>
                        {appState.apresentation.showQRCode.text !== '' && <p className="mb-2">{appState.apresentation.showQRCode.text}</p>}
                        <QRCode
                            className="bg-white p-3 qrcodeContainer"
                            value={
                                appState.apresentation.showQRCode.link !== ''
                                    ? appState.apresentation.showQRCode.link
                                    : domainConfig.aplicativos + '/?token=' + dataApplication.token
                            }
                        />
                    </div>
                </div>
            )}
            <div className="logoBox">
                <div className="logoBoxChildMiddle">
                    <img
                        src={domainConfig.imageServer + "/evento/" + dataApplication.evento_id + "/" + dataApplication.customizacao.logo}
                        alt="Logo"
                        className="App-logo pb-0"
                    ></img>
                    {appState.apresentation.title.status && (
                        <h5 className="my-5">
                            <TextWithLineBreaks text={appState.apresentation.title.value}></TextWithLineBreaks>
                        </h5>
                    )}
                </div>
            </div>
            <AnimatePresence>
                {showCurrentMedia && (
                    <motion.div
                        className="current-media-overlay"
                        style={{
                            position: 'absolute',
                            top: `calc(50% - ${appState.apresentation.configs.newImageHeight / 2}px)`,
                            left: `calc(50% - ${appState.apresentation.configs.newImageWidth / 2}px)`,
                            transform: 'translate(-50%, -50%)',
                            zIndex: 9999,
                        }}
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        exit={{ scale: 0, opacity: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        <img
                            src={currentMedia.media}
                            alt="Current Media"
                            style={{
                                width: `${appState.apresentation.configs.newImageWidth}px`,
                                height: `${appState.apresentation.configs.newImageHeight}px`,
                                objectFit: 'cover',
                            }}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
            <Gallery
                images={mediaItems}
                columns={appState.apresentation.configs.columns}
                rows={appState.apresentation.configs.rows}
                imageWidth={appState.apresentation.configs.width}
                imageHeight={appState.apresentation.configs.height}
                padding={appState.apresentation.configs.padding}
                showCurrentMedia={showCurrentMedia}
            />
        </>
    );
};

export default GalleryBoardAdmin;
