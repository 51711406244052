import React, { useRef, useState, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useAppState } from '../context/AppContext.js'
import { PageLoader } from "../../../components/Elements"
import { useGlobalState } from '../../../hooks/useCustomization.js';

const Ranking = () => {
    const { dataApplication } = useGlobalState();
    const { appState, setStart, getHistory } = useAppState();
    const [rankingData, setRankingData] = useState(false);
    const containerRef = useRef(null);
    const elementRef = useRef(null);

    useEffect(() => {
        // Function to fetch and wait for history data
        const fetchData = async () => {
            try {
                const data = await getHistory(); // Wait for getHistory to complete
                setRankingData(data); // Use the data to set chartData
            } catch (error) {
                console.error('Failed to fetch history:', error);
            }
        };

        fetchData(); // Call the fetchData function
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function scrollTo(element, to, duration) {
        Math.easeInOutQuad = function (t, b, c, d) {
            t /= d / 2;
            if (t < 1) return c / 2 * t * t + b;
            t--;
            return -c / 2 * (t * (t - 2) - 1) + b;
        };
        var start = element.scrollTop,
            change = to - start,
            currentTime = 0,
            increment = 20;
        var animateScroll = function () {
            currentTime += increment;
            var val = Math.easeInOutQuad(currentTime, start, change, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };
        animateScroll();
    }

    const handleScroll = () => {
        const container = containerRef.current;
        const element = elementRef.current;
        if (container && element) {
            // Directly use clientHeight for container height
            const elementOffset = element.offsetTop - container.clientHeight / 2 + 50;
            scrollTo(container, elementOffset - 10, 600);
        }
    };

    return (
        <AnimatePresence mode={'wait'}>
            {rankingData ? (
                <>
                    <div className='col-md-12 px-3' key={'rankingData'}>
                        <div className="mb-3">
                            <div className="cardRanking">
                                <div className="cardRankingHeader">Ranking (TOP 100)</div>
                                <motion.ul layout ref={containerRef}>
                                    <AnimatePresence>
                                        {appState.history && appState.history.map((props, index) => (
                                            <Item appState={appState} key={props.visitante_id} elementRef={elementRef} handleScroll={handleScroll} players={appState.history} props={props} index={index}></Item>
                                        ))}
                                    </AnimatePresence>
                                </motion.ul>
                            </div>
                        </div>
                        <div>
                            <button className="primaryColor primaryColorText btn mt-3" onClick={() => setStart(4)} >Continuar</button>
                        </div>
                    </div>
                </>
            ) : (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }} className='default-box' key={'loaderDiv'}>
                    <PageLoader color={dataApplication.customizacao.primaryColor} width={75}></PageLoader>
                </motion.div>
            )}
        </AnimatePresence>
    )
}

const Item = ({ elementRef, handleScroll, players, props, index }) => {
    const { dataApplication } = useGlobalState();
    let indexB = index
    if (index + 1 === players.length) {
        setTimeout(() => {
            handleScroll()
        }, 250);
    }
    indexB++
    return (
        <motion.li ref={props.visitante_id === dataApplication.visitante.visitante_id ? elementRef : undefined} className={`d-flex align-items-center position-relative p ${props.visitante_id === dataApplication.visitante.visitante_id ? "contrastPlayer" : ""}`}>
            <span className="medalRankingToggle text-center">
                {indexB}
            </span>
            <div className="rankingTime">
                <span>{props.time}</span>
            </div>
            <div className="w-100 text-start">
                <div className="ms-3">
                    <p className="mb-0">
                        <FontAwesomeIcon icon={faStar} className="me-1" />
                        {props.score}
                    </p>
                    {dataApplication.customizacao.form.fieldsToShow && dataApplication.customizacao.form.fieldsToShow.map((inputID, index) => {
                        const field = dataApplication.customizacao.form.fields.find(field => field.inputID === inputID);
                        if (field && props.jsonVisitante[field.inputID]) {
                            return (
                                <p key={'textLabelRanking' + index} className="text-start mb-0 textLabelRanking">{props.jsonVisitante[field.inputID].value}</p>
                            );
                        } else {
                            return ""
                        }
                    })}
                </div>
            </div>
        </motion.li>
    );
};

export default Ranking